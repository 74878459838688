// Core
import React from 'react';
import PropTypes from 'prop-types';

// Custom Components
import Checkbox from '..';

// Stylesheet
import styles from '../index.module.scss';

const TextCheckbox = ({ children, onChange, checked, value, ...rest }) =>
    <Checkbox onChange={onChange} checked={checked} value={value} {...rest}>
        <p className={`${styles.text} is-size-6`}>{children}</p>
    </Checkbox>

TextCheckbox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    children: PropTypes.string,
    cssClass: PropTypes.string
};

TextCheckbox.defaultProps = {
    checked: false,
    onChange: () => console.log('Checkbox Changed'),
    value: 'foobar',
    children: 'Checkbox'
};

export default TextCheckbox;