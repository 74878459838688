// Redux
import { connect } from 'react-redux';

import Component from './Component';

const mapStateToProps = state => ({
    state: state.noShop,
    details: state.details
});

const Summary = connect(mapStateToProps)(Component);

export default Summary;