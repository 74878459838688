// Core
import { connect } from 'react-redux';
// Actions
import { setSelection, setInner } from '../../../../ducks/noShop/Methods';

import Component from './Component';

const mapStateToProps = state => ({
    selection: state.noShop.q14.selection,
    inner: state.noShop.q14.inner,
});

const mapDispatchToProps = {
    setSelection,
    setInner,
};

const Q14 = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Q14;