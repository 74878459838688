// Core
import React from 'react';
import PropTypes from 'prop-types'

// Assets
import LogoHeraldbee from '../../../assets/logo_hb_reversed.svg';
import logoForbes from '../../../assets/logo_forbes.svg';
import logoGooglePartner from '../../../assets/GPartner.jpg';

// Stylesheet
import styles from './index.module.scss';

const BaseLayout = ({ children }) => {
    return (
        <section className={styles.container}>
            <header className={styles.header}>
                <div className={styles.headerHbPartners}>
                    <img className={styles.headerLogoPartnerG} src={logoGooglePartner} alt="Our Main Partner Logo" height="21px" />
                    <img className={styles.headerLogoPartnerForbes} src={logoForbes} alt="Forbes Logo" height="31px" />
                </div>
                <img className={styles.headerLogo} src={LogoHeraldbee} alt="Heraldbee Logo" height="37px" />
            </header>
            <main className={styles.content}>
                {children}
            </main>
        </section>
    );
};

BaseLayout.propTypes = {
    children: PropTypes.node,
};

export default BaseLayout