// Actions

const SET_NO_SHOP_QUESTION_SELECTION_VALUE = 'SET_NO_SHOP_QUESTION_SELECTION_VALUE';
const SET_NO_SHOP_QUESTION_SELECTION_VALUE_BY_KEY = 'SET_NO_SHOP_QUESTION_SELECTION_VALUE_BY_KEY';
const SET_NO_SHOP_QUESTION_INNER_VALUE = 'SET_NO_SHOP_QUESTION_INNER_VALUE';
const SET_NO_SHOP_QUESTION_EXTRA_VALUE = 'SET_NO_SHOP_QUESTION_EXTRA_VALUE';

// Reducer

const initialState = {
    q1: {
        title: 'Jaki układ sklepu Ci najbardziej odpowiada:',
        selection: null,
    },
    q2: {
        title: 'Wybierz elementy bez których nie wyobrażasz sobie zrobienia Twojego sklepu internetowego',
        selection: {
            newsletter: null,
            blog: null,
            aktualności: null,
            kilkaJęzyków: null,
            sliderNaStronie: null,
            wymagania: null
        },
        inner: {
            kilkaJęzyków: null
        }
    },
    q3: {
        title: 'W jakiej branży będziesz działał',
        selection: null,
    },
    q4: {
        title: 'Na jakich obszarach geograficznych chciałbyś działać (pamietaj o konieczności tłumaczeń produktów i odpowiedniej obsługi klienta):',
        selection: {
            region: null,
            kraj: null
        }
    },
    q5: {
        title: 'Czy posiadasz już konta Social Media?',
        selection: null,
        inner: {
            facebook: null,
            instagram: null,
            inne: null
        }
    },
    q6: {
        title: 'Czy posiadasz własne logo:',
        selection: null
    },
    q7: {
        title: 'Czy posiadasz konto Google (adres poczty w obrębie domeny gmail) na którym chciałbyś mieć skonfigurowane narzędzia analityczne Google tj. Google Analytics, Google Search Console, Google Ads, Google Merchant Center?',
        selection: null,
        inner: {
            gmail: null
        }
    },
    q8: {
        title: 'Do kogo chcesz przede wszystkim kierować swoje produkty, zcharakteryzuj docelowego klienta (np. płeć, wiek, lokalizacja, dochód, zainteresowania, przyzwyczajenia):',
        selection: {
            charakterystyka: null
        }
    },
    q9: {
        title: 'Czy korzystasz z programów magazynowych:',
        selection: null,
        inner: {
            selection: null
        },
        extra: {
            hurtownie: null
        }
    },
    q10: {
        title: ' Czy masz jakiś program do faktur?',
        selection: null,
        inner: {
            selection: null,
            inny: null
        }
    },
    q11: {
        title: 'Czy masz już wybranych kurierów?',
        selection: null,
        inner: {
            selection: null,
            inny: null
        }
    },
    q12: {
        title: 'Czy masz już konta na innych portalach (Market Place’ach) z którymi chciałbyś się zintegrować:',
        selection: null,
        inner: {
            facebook: null,
            allegro: null,
            opineo: null,
            ceneo: null,
            ceneoZaufaneOpinie: null,
            amazon: null,
            ebay: null,
            inne: null
        },
        extra: {
            facebookEMail: null
        }
    },
    q13: {
        title: 'Czy masz już wybranego operatora płatności z którym chciałbyś się zintegrować:',
        selection: null,
        inner: {
            przelewy24: null,
            dotPay: null,
            payPal: null,
            payU: null,
            przelewNaRachunek: null,
            imoje: null,
            inne: null
        }
    },
    q14: {
        title: 'Czy masz system mailingowy (lub Marketing Automation) z którym chciałbyś się zintegrować:',
        selection: null,
        inner: {
            selection: null,
            inne: null
        }
    }
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_NO_SHOP_QUESTION_SELECTION_VALUE:
            return {
                ...state,
                [action.question]: {
                    ...state[action.question],
                    selection: action.value
                }
            };

        case SET_NO_SHOP_QUESTION_SELECTION_VALUE_BY_KEY:
            return {
                ...state,
                [action.question]: {
                    ...state[action.question],
                    selection: {
                        ...state[action.question].selection,
                        [action.selectionKey]: action.value
                    }
                }
            };

        case SET_NO_SHOP_QUESTION_INNER_VALUE:
            return {
                ...state,
                [action.question]: {
                    ...state[action.question],
                    inner: {
                        ...state[action.question].inner,
                        [action.innerKey]: action.value
                    }
                }
            }

        case SET_NO_SHOP_QUESTION_EXTRA_VALUE:
            return {
                ...state,
                [action.question]: {
                    ...state[action.question],
                    extra: {
                        ...state[action.question].extra,
                        [action.extraKey]: action.value
                    }
                }
            }
        
        default: return state;
    }
};
export default reducer;

// Action Creators

export const actionNoShopSetQuestionSelectionValue = (question, value) => ({ type: SET_NO_SHOP_QUESTION_SELECTION_VALUE, question, value });
export const actionNoShopSeqQuestionSelectionValueByKey = (question, selectionKey, value) => ({ type: SET_NO_SHOP_QUESTION_SELECTION_VALUE_BY_KEY, question, selectionKey, value });
export const actionNoShopSetQuestionInnerValue = (question, innerKey, value) => ({ type: SET_NO_SHOP_QUESTION_INNER_VALUE, question, innerKey, value });
export const actionNoShopSetQuestionExtraValue = (question, extraKey, value) => ({ type: SET_NO_SHOP_QUESTION_EXTRA_VALUE, question, extraKey, value });

