// Core
import React from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { TextField as MaterialTextField } from '@material-ui/core';

// Stylesheet
import styles from '../index.module.scss';
import { useStylesTextArea } from '../index.material';

const TextField = ({ placeholder, label, value, rows, children, ...rest }) => {

    const materialStyles = useStylesTextArea();

    return (
        <div className={styles.containerArea}>
            <div className={styles.contentArea}>
                {children}
            </div>
            <MaterialTextField
                multiline
                rows={rows}
                className={materialStyles.root}
                placeholder={placeholder}
                label={label}
                value={value || ''}
                variant="outlined"
                {...rest}
            />
        </div>
    );
};

TextField.propTypes = {
    placeholder: PropTypes.node,
    label: PropTypes.node,
    value: PropTypes.any,
    rows: PropTypes.number,
    onChange: PropTypes.func,
    children: PropTypes.node
};

TextField.defaultProps = {
    placeholder: 'placeholder',
    label: 'label',
    rows: 5,
    onChange: () => console.log('TextField change'),
    children: 'Text Field'
};

export default TextField;