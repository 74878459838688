// Core
import React from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { Button as MaterialButton } from '@material-ui/core';

// Stylesheet
import { useStylesBase, useStylesTypes } from './index.material';

const Button = ({ children, onClick, disabled, theme, type, ...rest }) => {
    const materialStyles = useStylesBase(theme);
    const materialStylesType = useStylesTypes(theme);

    return (
        <MaterialButton variant="contained" className={`${materialStyles.root} ${type === 'secondary' ? materialStylesType.secondaryType : materialStylesType.primaryType}`} onClick={onClick} disabled={disabled} {...rest}>
            {children}
        </MaterialButton>
    );
};

Button.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
    theme: PropTypes.shape({
        baseBackground: PropTypes.string,
        hoverBackground: PropTypes.string,
        rippleColor: PropTypes.string,
        typographyStyles: PropTypes.objectOf(PropTypes.string)
    }),
    type: PropTypes.oneOf(['primary', 'secondary'])
};

Button.defaultProps = {
    onClick: () => console.log('Button Clicked'),
    disabled: false,
    children: 'Button',
    type: 'primary'
};

export default Button;