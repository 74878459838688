// Core
import React from 'react';
import PropTypes from 'prop-types';

// Custom Components
import TextArea from '..';

// Stylesheet
import styles from '../../index.module.scss';

const TextTextArea = ({ placeholder, label, value, rows, onChange, children, ...rest }) => {
    return (
        <TextArea
            rows={rows}
            placeholder={placeholder}
            label={label}
            value={value || ''}
            onChange={onChange}
            {...rest}
        >
            <p className={`${styles.text} is-size-6`}>{children}</p>
        </TextArea>
    );
};

TextTextArea.propTypes = {
    placeholder: PropTypes.node,
    label: PropTypes.node,
    value: PropTypes.any,
    onChange: PropTypes.func,
    children: PropTypes.string,
    rows: PropTypes.number.isRequired,
};

TextTextArea.defaultProps = {
    placeholder: 'placeholder',
    label: 'label',
    rows: 5,
    onChange: () => console.log('TextArea change'),
    children: 'Text Area'
};

export default TextTextArea;