// Core
import React from 'react';
import { useHistory } from 'react-router-dom';

// Custom Components
import QuestionPage from '../../../../../pages/QuestionPage';
import Question from '../../../../../components/primitive/Question';
import QuestionElement from '../../../../../components/complex/QuestionElement';
import ExpandableQuestionDetails from '../../../../../components/complex/ExpandableQuestionDetails';

const Q6 = ({ selection, inner, extra, texts, setSelection, setInner, setExtra }) => {
    let history = useHistory();

    const onClickNavigate = direction => () => {
        history.push(direction === 'back' ? '/withShop/q5' : '/withShop/q7');
    };

    const onChange = e => {
        setSelection('q6', e.target.value);
    };

    const onChangeInner = key => e => {
        if (!!inner[key]) {
            setInner('q6', key, null)
        } else {
            setInner('q6', key, e.target.value)
        }
    };

    const onChangeInnerText = e => {
        setInner('q6', 'inne', e.target.value)
    };

    const onChangeExtra = key => e => {
        setExtra('q6', key, e.target.value);
    };

    return (
        <QuestionPage onClickLeft={onClickNavigate('back')} onClickRight={onClickNavigate()}>
            <Question
                title={texts.title}
                questionNumber={6}
                questionTotal={7}
            >
                {
                    texts.content.map((field, i) =>
                        <div key={i}>
                            <QuestionElement
                                questionField={field}
                                stateValue={selection}
                                onChange={onChange}
                            />
                            {
                                field.content && field.content.length > 0
                                    ? (
                                        <ExpandableQuestionDetails
                                            fields={field.content}
                                            onChangeInner={onChangeInner}
                                            onChangeInnerText={onChangeInnerText}
                                            onChangeExtra={onChangeExtra}
                                            stateValueInner={inner}
                                            stateValueInnerText={inner.inne}
                                            stateValueExtra={extra.facebookEMail}
                                            isOpen={selection === field.value}
                                        />
                                    ) : (
                                        null
                                    )
                            }
                        </div>
                    )
                }
            </Question>
        </QuestionPage>
    );
};

export default Q6;