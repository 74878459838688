import { 
    actionWithShopSetQuestionSelectionValue,
    actionWithShopSeqQuestionSelectionValueByKey,
    actionWithShopSetQuestionExtraValue,
    actionWithShopSetQuestionInnerValue
} from '..';

export const setSelection = (question, value) => actionWithShopSetQuestionSelectionValue(question, value);

export const setSelectionByKey = (question, selectionKey, value) => actionWithShopSeqQuestionSelectionValueByKey(question, selectionKey, value);

export const setInner = (question, innerKey, value) => actionWithShopSetQuestionInnerValue(question, innerKey, value);

export const setExtra = (question, extraKey, value) => actionWithShopSetQuestionExtraValue(question, extraKey, value);