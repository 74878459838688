// Core
import React from 'react';
import PropTypes from 'prop-types';

// Custom Components
import Select from '..';

// Stylesheet
import styles from '../../index.module.scss';

const TextSelect = ({ label, value, optionList, onChange, children, ...rest }) => {
    return (
        <Select
            label={label}
            value={value || ''}
            optionList={optionList}
            onChange={onChange}
            {...rest}
        >
            <p className={`${styles.text} is-size-6`}>{children}</p>
        </Select>
    );
};

TextSelect.propTypes = {
    label: PropTypes.node,
    value: PropTypes.any,
    optionList: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string
        })
    ),
    onChange: PropTypes.func,
    children: PropTypes.string
};

TextSelect.defaultProps = {
    label: 'label',
    onChange: () => console.log('TextField change'),
    children: 'Text Field',
    value: '',
    optionlist: [
        {
            value: 'foo',
            label: 'foo'
        },
        {
            value: 'bar',
            label: 'bar'
        }
    ]
};

export default TextSelect;