// Core
import { createStore, combineReducers } from 'redux';

// Reducers
import noShop from './ducks/noShop';
import withShop from './ducks/withShop';
import details from './ducks/details';

const rootReducer = combineReducers({
    noShop,
    withShop,
    details
});

const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;