// Core
import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

// Custom Components
import TextButton from '../../components/primitive/Button/TextButton';
import BaseLayout from '../../components/layout/BaseLayout';
import NoShopRouter from '../NoShopRouter';
import WithShopRouter from '../WithShopRouter';

// Stylesheet
import styles from './index.module.scss'

const MainRouter = () => {
    let history = useHistory();

    const onClick = direction => {
        history.push(direction === 'noShop' ? '/noShop' : '/withShop');
    };

    useEffect(() => {
        if (history.location && history.location.search) {
            if (history.location.search === '?questionaire=noShop') {
                history.push('/noShop');
            } else if (history.location.search === '?questionaire=withShop') {
                history.push('/withShop');
            };
        }
    }, [ history ]);

    return (
        <BaseLayout>
                <Switch>
                    <Route exact path="/">
                        <div className={styles.buttons}>
                            <TextButton onClick={() => onClick('noShop')}>Chcę założyć sklep</TextButton>
                            <TextButton type="secondary" onClick={() => onClick('withShop')}>Chce zautomatyzować sklep</TextButton>
                        </div>
                    </Route>
                    <Route path="/noShop">
                        <NoShopRouter />
                    </Route>
                    <Route path="/withShop">
                        <WithShopRouter />
                    </Route>
                </Switch>
        </BaseLayout>
    );
};

export default MainRouter;