// Core
import { connect } from 'react-redux';
// Actions
import { setSelection, setInner, setExtra } from '../../../../ducks/noShop/Methods';

import Component from './Component';

const mapStateToProps = state => ({
    selection: state.noShop.q9.selection,
    inner: state.noShop.q9.inner,
    extra: state.noShop.q9.extra
});

const mapDispatchToProps = {
    setSelection,
    setInner,
    setExtra
};

const Q9 = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Q9;