import { makeStyles } from "@material-ui/core/styles";
import { colorPalette } from '../../../styles';

export const useStylesBase = makeStyles(theme => ({
    root: {
        padding: '10px 20px',
        height: '36px',
        borderRadius: "4px",
        boxShadow: `0 0 10px rgba(0,0,0,.2)`,
        backgroundColor: theme.baseBackground || colorPalette.primaryYellow,

        "&:hover": {
            backgroundColor: theme.hoverBackground || colorPalette.secondaryYellow,
            boxShadow: `0 0 5px rgba(0,0,0,.05)`,
        },
        
        "& .MuiTouchRipple-root": {
			color: theme.rippleColor || colorPalette.white
        },
        
        "& .MuiButton-label": theme.typographyStyles ? theme.typographyStyles : {
			fontFamily: "Poppins",
			fontSize: "14px",
			fontWeight: "500",
			color: colorPalette.primaryBrown
		}
    }
}));

export const useStylesTypes = makeStyles(theme => ({
    primaryType: {
		boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.2)",
		backgroundColor: colorPalette.primaryYellow,
		fontWeight: "500",
		"&:hover": {
			backgroundColor: colorPalette.secondaryYellow
		},
		"& .Mui-focusVisible": {
			backgroundColor: colorPalette.secondaryYellow
		},
		"& .MuiTouchRipple-root": {
			color: colorPalette.white
		},
		"& .MuiButton-label": {
			fontFamily: "Roboto",
			fontSize: "14px",
			fontWeight: "500",
			fontStyle: "normal",
			fontStretch: "normal",
			lineHeight: "1.14",
			letterSpacing: "1.2px",
			textAlign: "left",
			color: colorPalette.primaryBrown
		}
	},
	secondaryType: {
		border: "solid 2px rgba(21,19,19,0.1)",
        backgroundColor: colorPalette.white,
		padding: "3px 10px",
		margin: "0",
		boxShadow: "unset",
		color: colorPalette.secondaryOrange,
		"& .MuiButton-label": {
			color: `${colorPalette.primaryOrange} !important`
		},
		"&:hover": {
            backgroundColor: `rgba(${colorPalette.secondaryOrangeRGB}, .2) !important`,
            border: `solid 2px rgba(${colorPalette.primaryBrownRGB}, 0.15)`,
		}
	},
}));