// Core
import React from 'react';
import { useHistory } from 'react-router-dom';

// Custom Components
import SummaryPage from '../../../../pages/SummaryPage';

const Summary = ({ state, details }) => {
    let history = useHistory();

    const onClickLeft = () => {
        history.push('/noShop/q14');
    };

    return (
        <SummaryPage
            designation="noShop"
            onClickLeft={onClickLeft}
            state={state}
            details={details}
        />
    );
};

export default Summary;