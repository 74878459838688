// Core
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Custom Components
import QuestionPage from '../../../../../pages/QuestionPage';
import Question from '../../../../../components/primitive/Question';
import QuestionElement from '../../../../../components/complex/QuestionElement';

const Q1 = ({ selection, texts, setSelection }) => {
    const [ textfield, setTextfield ] = useState('');
    let history = useHistory();

    const onClickNavigate = direction => () => {
        history.push(direction === 'back' ? '/withShop' : '/withShop/q2');
    };

    const onChange = e => {
        if (textfield && textfield.length > 0) {
            setTextfield('');
        }
        setSelection('q1', e.target.value);
    };


    const onChangeTextfield = e => {
        setTextfield(e.target.value);
        setSelection('q1', e.target.value);
    };

    return (
        <QuestionPage onClickLeft={onClickNavigate('back')} onClickRight={onClickNavigate()}>
            <Question
                title={texts.title}
                questionNumber={1}
                questionTotal={7}
            >
                {
                    texts.content.map((field, i) =>
                        <div key={i}>
                            <QuestionElement
                                questionField={field}
                                stateValue={field.type === 'TextField' ? textfield : selection}
                                onChange={field.type === 'TextField' ? onChangeTextfield : onChange}
                            />
                        </div>
                    )
                }
            </Question>
        </QuestionPage>
    );
};

export default Q1;