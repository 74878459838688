// Core
import React from 'react';
import PropTypes from 'prop-types';

// Stylesheet
import styles from './index.module.scss';

const Introduction = ({ intro }) => {
    return (
        <div className={`${styles.container}`}>
            <h1 className={`${styles.title} title is-family-secondary is-size-1 is-size-3-desktop`}>
                <strong className={`${styles.titleBold}`}>
                    {intro.title.bold}
                </strong>
                {' ' + intro.title.regular}
            </h1>
            <p className={`${styles.description} is-size-6`}>{intro.description}</p>
        </div>
    );
};

Introduction.propTypes = {
    intro: PropTypes.shape({
        title: PropTypes.shape({
            bold: PropTypes.string,
            regular: PropTypes.string
        }),
        description: PropTypes.string
    })
};

Introduction.defaultProps = {
    intro: {
        title: {
            bold: 'Title',
            regular: 'title',
        },
        description: 'Description'
    }
};

export default Introduction;