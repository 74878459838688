// Core
import { connect } from 'react-redux';
// Actions
import { setSelection } from '../../../../ducks/noShop/Methods';

import Component from './Component';

const mapStateToProps = state => ({
    selection: state.noShop.q1.selection
});

const mapDispatchToProps = {
    setSelection
};

const Q1 = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Q1;