// Core
import { connect } from 'react-redux';
// Actions
import { setSelection, setInner, setExtra } from '../../../../ducks/withShop/Methods';

import Component from './Component';

const mapStateToProps = state => ({
    selection: state.withShop.q6.selection,
    inner: state.withShop.q6.inner,
    extra: state.withShop.q6.extra
});

const mapDispatchToProps = {
    setSelection,
    setInner,
    setExtra
};

const Q6 = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Q6;