// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import Lightbox from 'react-image-lightbox';

// Custom Components
import Radio from '../Radio';

// Stylesheet
import styles from './index.module.scss';
import 'react-image-lightbox/style.css';

const ImageRadio = ({ header, description, imgs, link, checked, onChange, value }) => {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ imgIndex, setImgIndex ] = useState(0);

    return (
        <div className={`${styles.container} ${checked ? styles.containerChecked : ''}`}>
            <div className={`${styles.radio}`}>
                <Radio checked={checked} onChange={onChange} value={value}/>
            </div>
            <div className={`${styles.content}`}>
                <div className={`${styles.contentTexts}`}>
                    <h3 className={`${styles.contentTextsHeader} title is-size-4 is-size-5-mobile`}>{header}</h3>
                    <p className={`${styles.contentTextsDescription} is-size-6`}>{description}</p>
                    {
                        link && link.url
                            ? (
                                <a className={`${styles.contentTextsLink}`} rel="noopener noreferrer" target="_blank" href={link.url}>{link.text}</a>
                            ) : (
                                <p className={`${styles.contentTextsLinkInactive}`}>{/* placeholder text here if needed */}</p>
                            )
                    }
                </div>
                <div className={`${styles.contentImg}`}>
                    <button
                        className={imgs[0].isFeatured ? styles.contentImgInner : styles.contentImgInnerHidden}
                        onClick={() => setIsOpen(true)}
                    >
                        <img src={imgs[0].src} alt={imgs[0].alt} />
                    </button>
                    {
                        isOpen && (
                            <Lightbox
                                mainSrc={imgs[imgIndex].src}
                                nextSrc={imgs[(imgIndex + 1) % imgs.length].src}
                                prevSrc={imgs[(imgIndex + imgs.length - 1) % imgs.length].src}
                                onCloseRequest={() => setIsOpen(false)}
                                onMovePrevRequest={() => setImgIndex((imgIndex + imgs.length - 1) % imgs.length)}
                                onMoveNextRequest={() => setImgIndex((imgIndex + 1) % imgs.length)}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

ImageRadio.propTypes = {
    header: PropTypes.string,
    description: PropTypes.string,
    imgs: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string,
            isFeatured: PropTypes.bool
        })
    ),
    link: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string
    }),
    checked: PropTypes.bool,
    onChange: PropTypes.func
};

ImageRadio.defaultProps = {
    header: 'Title',
    description: 'Description',
    imgs: {
        src: '',
        alt: '',
        isFeatured: false
    },
    link: {
        text: 'link text',
        url: '#'
    },
    checked: false,
    onChange: () => console.log('change')
};

export default ImageRadio;