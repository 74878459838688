// Core
import React from 'react';
import { useHistory } from 'react-router-dom';

// Custom Components
import IntroPage from '../../../../pages/IntroPage';

// Custom Modules
import { textsWithShop } from '../../../../texts';

const Intro = ({ details, setDetailsValueByKey }) => {
    let history = useHistory();

    const onClick = () => {
        history.push('/withShop/q1');
    };

    return (
        <IntroPage
            intro={textsWithShop.intro}
            onClick={onClick}
            onChange={setDetailsValueByKey}
            details={details}
        />
    );
};

export default Intro;