import BBimg1 from '../assets/zrzuty-projektow-hb/bbshop/home @1x.jpg';
import BBimg2 from '../assets/zrzuty-projektow-hb/bbshop/kategoria @1x.jpg';
import BBimg3 from '../assets/zrzuty-projektow-hb/bbshop/produkt @1x.jpg';

import WARMIANKAimg1 from '../assets/zrzuty-projektow-hb/warmianka/home.jpg';
import WARMIANKAimg2 from '../assets/zrzuty-projektow-hb/warmianka/category.jpg';
import WARMIANKAimg3 from '../assets/zrzuty-projektow-hb/warmianka/product.jpg';

import GNOMEimg1 from '../assets/zrzuty-projektow-hb/gnome/01 home.jpg';
import GNOMEimg2 from '../assets/zrzuty-projektow-hb/gnome/02 category.jpg';
import GNOMEimg3 from '../assets/zrzuty-projektow-hb/gnome/03 product.jpg';

import ROLETAimg1 from '../assets/zrzuty-projektow-hb/mojaroleta/01 home @1x.jpg';
import ROLETAimg2 from '../assets/zrzuty-projektow-hb/mojaroleta/02 category @1x.jpg';
import ROLETAimg3 from '../assets/zrzuty-projektow-hb/mojaroleta/03 product @1x.jpg';

import SYMPETRUMimg1 from '../assets/zrzuty-projektow-hb/sympetrum/01 Home.png';
import SYMPETRUMimg2 from '../assets/zrzuty-projektow-hb/sympetrum/02 Category.png';
import SYMPETRUMimg3 from '../assets/zrzuty-projektow-hb/sympetrum/03 Product.png';

export const textsNoShop = {
    intro: {
        title: {
            bold: 'Witam Cię',
            regular: 'w Twoim osobistym konfiguratorze sklepu internetowego'
        },
        description: 'W następnych krokach zostaniesz poproszony o odpowiedź na 14 podstawowych pytań dotyczących Twoich preferencji co do sprzedaży w Internecie. Odpowiadając na nie rozpoznamy Twoje potrzeby i charakter Twojego biznesu.'
    },
    questions: [
        {
            title: 'Jaki układ sklepu Ci najbardziej odpowiada:',
            content: [
                {
                    type: 'RadioField',
                    header: 'SKLEP NA DOBRY START',
                    description: 'Chciałbyś przenieść swoją sprzedaż do Internetu? Sprzedajesz już na allegro czy facebooku? Ten układ jest dla Ciebie. Skorzystaj z dobrodziejstw automatyzacji i unikaj drogich prowizji tworząc własną sprzedaż.',
                    imgs: [
                        {
                            src: BBimg1,
                            alt: 'Strona Domowa',
                            isFeatured: true
                        },
                        {
                            src: BBimg2,
                            alt: 'Strona Kategorii',
                        },
                        {
                            src: BBimg3,
                            alt: 'Strona Produktu',
                        }
                    ],
                    link: {
                        text: 'Zobacz przykład',
                        url: 'https://bb-shop.pl/'
                    },
                    value: 'sklep na dobry start'
                },
                {
                    type: 'RadioField',
                    header: 'BUDUJE SWÓJ BRAND',
                    description: 'Masz pomysł i chcesz być charakterystyczny? Wiesz do kogo targetować swoją ofertę i wiesz, że Twój produkt się sprzeda? Skorzystaj z naszego układu uwidaczniającego Twoją markę.',
                    imgs: [
                        {
                            src: WARMIANKAimg1,
                            alt: 'Strona Domowa',
                            isFeatured: true
                        },
                        {
                            src: WARMIANKAimg2,
                            alt: 'Strona Kategorii',
                        },
                        {
                            src: WARMIANKAimg3,
                            alt: 'Strona Produktu',
                        }
                    ],
                    link: {
                        text: 'Zobacz przykład',
                        url: 'https://warmianka-styl.pl/'
                    },
                    value: 'buduje swój brand'
                },
                {
                    type: 'RadioField',
                    header: 'MOJA NISZA',
                    description: 'Masz już ugruntowaną pozycję w swojej niszy i chcesz zbudować zautomatyzowany sklep by zwiększyć możliwości dystrybucji swoich produktów. Zdecyduj się na nasz układ.',
                    imgs: [
                        {
                            src: SYMPETRUMimg1,
                            alt: 'Strona Domowa',
                            isFeatured: true
                        },
                        {
                            src: SYMPETRUMimg2,
                            alt: 'Strona Kategorii',
                        },
                        {
                            src: SYMPETRUMimg3,
                            alt: 'Strona Produktu',
                        }
                    ],
                    link: {
                        text: 'Zobacz przykład',
                        url: ''
                    },
                    value: 'moja nisza'
                },
                {
                    type: 'RadioField',
                    header: 'KONFIGUROWALNY PRODUKT',
                    description: 'Masz wiele wariacji jednego produktu w ofercie. Skorzystaj z możliwości jakie Ci dają nasze sklepy i dowolnie zmieniaj podstrony produktów.',
                    imgs: [
                        {
                            src: ROLETAimg1,
                            alt: 'Strona Domowa',
                            isFeatured: true
                        },
                        {
                            src: ROLETAimg2,
                            alt: 'Strona Kategorii',
                        },
                        {
                            src: ROLETAimg3,
                            alt: 'Strona Produktu',
                        }
                    ],
                    link: {
                        text: 'Zobacz przykład',
                        url: ''
                    },
                    value: 'konfigurowalny produkt'
                },
                {
                    type: 'RadioField',
                    header: 'ZARABIAM NA USŁUGACH',
                    description: 'Sprzedajesz usługi? Opłaty abonamentowe? Z nami wszystko jest możliwe. Zautomatyzuj możliwości sprzedaży przez Internet.',
                    imgs: [
                        {
                            src: GNOMEimg1,
                            alt: 'Strona Domowa',
                            isFeatured: true
                        },
                        {
                            src: GNOMEimg2,
                            alt: 'Strona Kategorii',
                        },
                        {
                            src: GNOMEimg3,
                            alt: 'Strona Produktu',
                        }
                    ],
                    link: {
                        text: 'Zobacz przykład',
                        url: ''
                    },
                    value: 'zarabiam na usługach'
                }
            ]
        },
        {
            title: 'Wybierz elementy bez których nie wyobrażasz sobie zrobienia Twojego sklepu internetowego',
            content: [
                {
                    type: 'Checkbox',
                    text: 'Newsletter',
                    value: 'newsletter',
                    content: null
                },
                {
                    type: 'Checkbox',
                    text: 'Blog',
                    value: 'blog',
                    content: null
                },
                {
                    type: 'Checkbox',
                    text: 'Aktualności',
                    value: 'aktualności',
                    content: null
                },
                {
                    type: 'Checkbox',
                    text: 'Slider na stronie',
                    value: 'sliderNaStronie',
                    content: null
                },
                {
                    type: 'Checkbox',
                    text: 'Kilka języków',
                    value: 'kilkaJęzyków',
                    content: [
                        {
                            type: 'TextArea',
                            label: 'Języki',
                            placeholder: 'angielski, niemiecki...',
                            text: 'Jakich:'
                        }
                    ]
                },
                {
                    type: 'TextArea',
                    label: 'Wymagania',
                    placeholder: 'Dodatkowe wymagania...',
                    text: 'Inne:',
                }
            ]
        },
        {
            title: 'W jakiej branży będziesz działał',
            content: [
                {
                    type: 'Select',
                    text: 'Wybrana branża:',
                    label: 'Branża',
                    optionList: [ // Requires Population
                        {
                            value: 'Apteki',
                            label: 'Apteki'
                        },
                        {
                            value: 'Bielizna',
                            label: 'Bielizna'
                        },
                        {
                            value: 'Biuro',
                            label: 'Biuro'
                        },
                        {
                            value: 'Biżuteria',
                            label: 'Biżuteria'
                        },
                        {
                            value: 'Dla dorosłych',
                            label: 'Dla dorosłych'
                        },
                        {
                            value: 'Dom',
                            label: 'Dom'
                        },
                        {
                            value: 'Dzieci',
                            label: 'Dzieci'
                        },
                        {
                            value: 'Edukacja',
                            label: 'Edukacja'
                        },
                        {
                            value: 'Fotografia',
                            label: 'Fotografia'
                        },
                        {
                            value: 'Galanteria',
                            label: 'Galanteria'
                        },
                        {
                            value: 'Gastronomia',
                            label: 'Gastronomia'
                        },
                        {
                            value: 'Hobby',
                            label: 'Hobby'
                        },
                        {
                            value: 'Inne',
                            label: 'Inne'
                        },
                        {
                            value: 'Komputery',
                            label: 'Komputery'
                        },
                        {
                            value: 'Kosmetyki',
                            label: 'Kosmetyki'
                        },
                        {
                            value: 'Księgarnia',
                            label: 'Księgarnia'
                        },
                        {
                            value: 'Kwiaciarnia',
                            label: 'Kwiaciarnia'
                        },
                        {
                            value: 'Meble',
                            label: 'Meble'
                        },
                        {
                            value: 'Motoryzacja',
                            label: 'Motoryzacja'
                        },
                        {
                            value: 'Muzyka',
                            label: 'Muzyka'
                        },
                        {
                            value: 'Narzędzia',
                            label: 'Narzędzia'
                        },
                        {
                            value: 'Odzież i obuwie',
                            label: 'Odzież i obuwie'
                        },
                        {
                            value: 'Ogród',
                            label: 'Ogród'
                        },
                        {
                            value: 'Przemysł',
                            label: 'Przemysł'
                        },
                        {
                            value: 'Rowery',
                            label: 'Rowery'
                        },
                        {
                            value: 'Sklep chemiczny',
                            label: 'Sklep chemiczny'
                        },
                        {
                            value: 'Sklep optyczny',
                            label: 'Sklep optyczny'
                        },
                        {
                            value: 'Sklep wielobranżowy',
                            label: 'Sklep wielobranżowy'
                        },
                        {
                            value: 'Sport',
                            label: 'Sport'
                        },
                        {
                            value: 'Sprzęt ADG i RTV',
                            label: 'Sprzęt ADG i RTV'
                        },
                        {
                            value: 'Telefony i akcesoria',
                            label: 'Telefony i akcesoria'
                        },
                        {
                            value: 'Turystyka',
                            label: 'Turystyka'
                        },
                        {
                            value: 'Upominki',
                            label: 'Upominki'
                        },
                        {
                            value: 'Wędkastwo',
                            label: 'Wędkastwo'
                        },
                        {
                            value: 'Zabawki',
                            label: 'Zabawki'
                        },
                        {
                            value: 'Zioła',
                            label: 'Zioła'
                        },
                        {
                            value: 'Zwierzęta',
                            label: 'Zwierzęta'
                        },
                    ]
                }
            ]
        },
        {
            title: 'Na jakich obszarach geograficznych chciałbyś działać (pamietaj o konieczności tłumaczeń produktów i odpowiedniej obsługi klienta):',
            content: [
                {
                    type: 'Select',
                    text: 'Region',
                    label: 'Region',
                    optionList: [
                        {
                            value: 'Afryka',
                            label: 'Afryka'
                        },
                        {
                            value: 'Ameryka Północna',
                            label: 'Ameryka Północna' 
                        },
                        {
                            value: 'Ameryka Południowa',
                            label: 'Ameryka Południowa'
                        },
                        {
                            value: 'Australia i Oceania',
                            label: 'Australia i Oceania'
                        },
                        {
                            value: 'Azja',
                            label: 'Azja'
                        },
                        {
                            value: 'Europa',
                            label: 'Europa'
                        }
                    ]
                },
                {
                    type: 'TextArea',
                    text: 'Kraj (wypisz jaki/jakie):',
                    label: 'Kraj',
                    placeholder: 'Polska'
                }
            ]
        },
        {
            title: 'Czy posiadasz już konta Social Media?',
            content: [
                {
                    type: 'Radio',
                    text: 'Nie - poproszę was o ich założenie',
                    value: 'Nie',
                    content: null
                },
                {
                    type: 'Radio',
                    text: 'Tak',
                    value: 'Tak',
                    content: [
                        {
                            type: 'TextField',
                            forced: true,
                            stateKey: 'facebook',
                            text: 'Facebook:',
                            label: 'Platforma',
                            placeholder: 'https://www.facebook...',
                        },
                        {
                            type: 'TextField',
                            forced: true,
                            stateKey: 'instagram',
                            text: 'Instagram:',
                            label: 'Platforma',
                            placeholder: 'https://www.instagram...',
                        },
                        {
                            type: 'TextArea',
                            stateKey: 'inne',
                            text: 'Inne:',
                            label: 'Platforma',
                            placeholder: 'https://...',
                        }
                    ]
                }
            ],
        },
        {
            title: 'Czy posiadasz własne logo:',
            content: [
                {
                    type: 'Radio',
                    text: 'Nie - poproszę was o projekt',
                    value: 'Nie',
                    content: null
                },
                {
                    type: 'Radio',
                    text: 'Tak',
                    value: 'Tak',
                    content: null
                }
            ]
        },
        {
            title: 'Czy posiadasz konto Google (adres poczty w obrębie domeny gmail) na którym chciałbyś mieć skonfigurowane narzędzia analityczne Google tj. Google Analytics, Google Search Console, Google Ads, Google Merchant Center?',
            content: [
                {
                    type: 'Radio',
                    text: 'Tak',
                    value: 'Tak',
                    content: [
                        {
                            type: 'TextField',
                            stateKey: 'gmail',
                            text: 'Podaj nam Twój adres gmail:',
                            label: 'GMail',
                            placeholder: 'jankowalski@gmai.com'
                        }
                    ]
                },
                {
                    type: 'Radio',
                    text: 'Nie',
                    value: 'Nie',
                    content: null
                }
            ]
        },
        {
            title: 'Do kogo chcesz przede wszystkim kierować swoje produkty, zcharakteryzuj docelowego klienta (np. płeć, wiek, lokalizacja, dochód, zainteresowania, przyzwyczajenia):',
            content: [
                {
                    type: 'TextArea',
                    text: 'Charakterystyka docelowego klienta:',
                    label: 'Charakterystyka',
                    placeholder: 'Kobiety powyżej 30 roku życia...'
                }
            ]
        },
        {
            title: 'Czy korzystasz z programów magazynowych:',
            content: [
                {
                    type: 'Radio',
                    text: 'Nie - poproszę o rekomdację',
                    value: 'nie',
                    content: null
                },
                {
                    type: 'Radio',
                    text: 'Tak',
                    value: 'Tak',
                    content: [
                        {
                            type: 'Radio',
                            text: 'WF MAG',
                            value: 'WF_MAG',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'Integruję się z hurtownią',
                            value: 'Integruje się z hurtownią',
                            content: [
                                {
                                    type: 'Select',
                                    stateKey: 'hurtownie',
                                    text: 'Iloma? (wybierz liczbę):',
                                    label: 'Ilość',
                                    optionList: [
                                        {
                                            label: '1',
                                            value: '1'
                                        },
                                        {
                                            label: '2',
                                            value: '2'
                                        },
                                        {
                                            label: '3',
                                            value: '3'
                                        },
                                        {
                                            label: '4 i więcej',
                                            value: '4 i więcej'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: 'Radio',
                            text: 'Sam będę wpisywał stany magazynowe',
                            value: 'Sam będę wpisywał stany magazynowe',
                            content: null
                        },
                        {
                            type: 'TextField',
                            text: 'Inny system (jaki?):',
                            label: 'System',
                            placeholder: 'Twój program magazynowy...'
                        }
                    ]
                }
            ]
        },
        {
            title: ' Czy masz jakiś program do faktur?',
            content: [
                {
                    type: 'Radio',
                    text: 'Nie – Chciałbym aby sklep wystawiał faktury',
                    value: 'Nie',
                    content: null
                },
                {
                    type: 'Radio',
                    text: 'Tak',
                    value: 'Tak',
                    content: [
                        {
                            type: 'Radio',
                            text: 'Fakturownia',
                            value: 'fakturownia',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'iFirma',
                            value: 'iFirma',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'InFakt',
                            value: 'inFakt',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'wFirma',
                            value: 'wFirma',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'WF MAG',
                            value: 'WF MAG',
                            content: null
                        },
                        {
                            type: 'TextField',
                            text: 'Inny system (jaki?):',
                            label: 'System',
                            placholder: 'Twój program fakturowy...'
                        }
                    ]
                }
            ]
        },
        {
            title: 'Czy masz już wybranych kurierów?',
            content: [
                {
                    type: 'Radio',
                    text: 'Nie - poproszę o rekomendację',
                    value: 'Nie',
                    content: null
                },
                {
                    type: 'Radio',
                    text: 'Tak',
                    value: 'Tak',
                    content: [
                        {
                            type: 'Radio',
                            text: 'Furgonetka',
                            value: 'Furgonetka',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'InPost',
                            value: 'InPost',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'UPS',
                            value: 'UPS',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'DHL',
                            value: 'DHL',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'FedEx',
                            value: 'FedEx',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'Paczka w Ruchu',
                            value: 'Paczka w Ruchu',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'Poczta Polska',
                            value: 'Poczta Polska',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'DPD',
                            value: 'DPD',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'Geis',
                            value: 'Geis',
                            content: null
                        },
                        {
                            type: 'TextField',
                            text: 'Inny (jaki?):',
                            label: 'Kurier',
                            placeholder: 'Wybrana firma kurierska...'
                        }
                    ]
                }
            ]
        },
        {
            title: 'Czy masz już konta na innych portalach (Market Place’ach) z którymi chciałbyś się zintegrować:',
            content: [
                {
                    type: 'Radio',
                    text: 'Nie',
                    value: 'Nie',
                    content: null
                },
                {
                    type: 'Radio',
                    text: 'Tak',
                    value: 'Tak',
                    content: [
                        {
                            type: 'Checkbox',
                            stateKey: 'facebook',
                            text: 'Facebook',
                            value: 'facebook',
                            content: [
                                {
                                    type: 'TextField',
                                    stateKey: 'facebookEMail',
                                    text: 'Podaj swój mail do logowania:',
                                    label: 'e-mail',
                                    placeholder: 'jankowalski@xyz.com'
                                }
                            ]
                        },
                        {
                            type: 'Checkbox',
                            stateKey: 'allegro',
                            text: 'Allegro',
                            value: 'Allegro',
                            content: null
                        },
                        {
                            type: 'Checkbox',
                            stateKey: 'opineo',
                            text: 'Opineo',
                            value: 'Opineo',
                            content: null
                        },
                        {
                            type: 'Checkbox',
                            stateKey: 'ceneo',
                            text: 'Ceneo',
                            value: 'Ceneo',
                            content: null
                        },
                        {
                            type: 'Checkbox',
                            stateKey: 'ceneoZaufaneOpinie',
                            text: 'Ceneo Zaufane opinie',
                            value: 'Ceneo Zaufane opinie',
                            content: null
                        },
                        {
                            type: 'Checkbox',
                            stateKey: 'amazon',
                            text: 'Amazon',
                            value: 'Amazon',
                            content: null
                        },
                        {
                            type: 'Checkbox',
                            stateKey: 'ebay',
                            text: 'Ebay',
                            value: 'Ebay',
                            content: null
                        },
                        {
                            type: 'TextField',
                            stateKey: 'inne',
                            label: 'inne',
                            text: 'Inne:',
                            placeholder: ''
                        }
                    ]
                }
            ]
        },
        {
            title: 'Czy masz już wybranego operatora płatności z którym chciałbyś się zintegrować:',
            content: [
                {
                    type: 'Radio',
                    text: 'Nie, poproszę o rekomendację',
                    value: 'Nie',
                    content: null
                },
                {
                    type: 'Radio',
                    text: 'Tak',
                    value: 'Tak',
                    content: [
                        {
                            type: 'Checkbox',
                            stateKey: 'przelewy24',
                            text: 'Przelewy24',
                            value: 'przelewy24',
                            content: null
                        },
                        {
                            type: 'Checkbox',
                            stateKey: 'dotPay',
                            text: 'DotPay',
                            value: 'dotPay',
                            content: null
                        },
                        {
                            type: 'Checkbox',
                            stateKey: 'payPal',
                            text: 'PayPal',
                            value: 'payPal',
                            content: null
                        },
                        {
                            type: 'Checkbox',
                            stateKey: 'payU',
                            text: 'PayU',
                            value: 'payU',
                            content: null
                        },
                        {
                            type: 'Checkbox',
                            stateKey: 'przelewNaRachunek',
                            text: 'Przelew na rachunek',
                            value: 'przelewNaRachunek',
                            content: null
                        },
                        {
                            type: 'Checkbox',
                            stateKey: 'imoje',
                            text: 'Imoje',
                            value: 'imoje',
                            content: null
                        },
                        {
                            type: 'TextField',
                            stateKey: 'inne',
                            text: 'Inny:',
                            label: 'Pośrednik',
                            placeholder: 'Twój pośrednik płatności...'
                        }
                    ]
                }
            ]
        },
        {
            title: 'Czy masz system mailingowy (lub Marketing Automation) z którym chciałbyś się zintegrować:',
            content: [
                {
                    type: 'Radio',
                    text: 'Nie - poproszę o rekomendację',
                    value: 'Nie',
                    content: null
                },
                {
                    type: 'Radio',
                    text: 'Tak',
                    value: 'Tak',
                    content: [
                        {
                            type: 'Radio',
                            text: 'Mailchimp',
                            value: 'Mailchimp',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'Freshmail',
                            value: 'Freshmail',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'GetResponse',
                            value: 'GetResponse',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'Sales Manago',
                            value: 'Sales Manago',
                            content: null
                        },
                        {
                            type: 'TextField',
                            text: 'Inny:',
                            label: 'System',
                            placeholder: 'Twój system mailingowy...'
                        }
                    ]
                }
            ]
        },
        {
            title: {
                bold: 'Dziękujemy! ',
                regular: 'Wiemy już wszystko. Podaj nam swoje dane kontaktowe a nasz konsultant skontaktuje się z Tobą w celu przedstawienia zasad współpracy.'   
            },
            content: [
                {
                    type: 'Summary'
                }
            ]
        }
    ]
};

export const textsWithShop = {
    intro: {
        title: {
            bold: 'Dziękujemy',
            regular: 'za zainteresowanie.'   
        },
        description: 'Poniżej zadamy Ci 7 pytań aby móc się przygotować lepiej do rozmowy na temat automatyzacji działań sklepu Internetowego.'
    },
    questions: [
        {
            title: 'Na jakiej platformie sklepowej masz opartą sprzedaż:',
            content: [
                {
                    type: 'Radio',
                    text: 'WooCommerce (WordPress)',
                    value: 'WooCommerce (WordPress)',
                    content: null
                },
                {
                    type: 'Radio',
                    text: 'PrestaShop',
                    value: 'PrestaShop',
                    content: null
                },
                {
                    type: 'Radio',
                    text: 'IAI',
                    value: 'IAI',
                    content: null
                },
                {
                    type: 'Radio',
                    text: 'Shoper',
                    value: 'Shoper',
                    content: null
                },
                {
                    type: 'Radio',
                    text: 'Magento',
                    value: 'Magento',
                    content: null
                },
                {
                    type: 'Radio',
                    text: 'Shopify',
                    value: 'Shopify',
                    content: null
                },
                {
                    type: 'TextField',
                    text: 'Inna:',
                    label: 'Platforma',
                    placeholder: 'Twoja platforma e-commerce...'
                }
            ]
        },
        {
            title: 'Na jakich obszarach geograficznych chciałbyś działać (pamietaj o konieczności tłumaczeń produktów i odpowiedniej obsługi klienta):',
            content: [
                {
                    type: 'Select',
                    text: 'Region',
                    label: 'Region',
                    optionList: [
                        {
                            value: 'Afryka',
                            label: 'Afryka'
                        },
                        {
                            value: 'Ameryka Północna',
                            label: 'Ameryka Północna' 
                        },
                        {
                            value: 'Ameryka Południowa',
                            label: 'Ameryka Południowa'
                        },
                        {
                            value: 'Australia i Oceania',
                            label: 'Australia i Oceania'
                        },
                        {
                            value: 'Azja',
                            label: 'Azja'
                        },
                        {
                            value: 'Europa',
                            label: 'Europa'
                        }
                    ]
                },
                {
                    type: 'TextArea',
                    text: 'Kraj (wypisz jaki/jakie):',
                    label: 'Kraj',
                    placeholder: 'Polska'
                }
            ]
        },
        {
            title: 'Czy posiadasz już konta Social Media?',
            content: [
                {
                    type: 'Radio',
                    text: 'Nie - poproszę was o ich założenie',
                    value: 'Nie',
                    content: null
                },
                {
                    type: 'Radio',
                    text: 'Tak',
                    value: 'Tak',
                    content: [
                        {
                            type: 'TextField',
                            stateKey: 'facebook',
                            forced: true,
                            text: 'Facebook:',
                            label: 'Platforma',
                            placeholder: 'https://www.facebook...',
                        },
                        {
                            type: 'TextField',
                            stateKey: 'instagram',
                            forced: true,
                            text: 'Instagram:',
                            label: 'Platforma',
                            placeholder: 'https://www.instagram...',
                        },
                        {
                            type: 'TextArea',
                            stateKey: 'inne',
                            text: 'Inne:',
                            label: 'Platforma',
                            placeholder: 'https://...',
                        }
                    ]
                }
            ],
        },
        {
            title: 'Czy posiadasz konto Google (adres poczty w obrębie domeny gmail) na którym chciałbyś mieć skonfigurowane narzędzia analityczne Google tj. Google Analytics, Google Search Console, Google Ads, Google Merchant Center?',
            content: [
                {
                    type: 'Radio',
                    text: 'Tak',
                    value: 'Tak',
                    content: [
                        {
                            type: 'TextField',
                            stateKey: 'gmail',
                            text: 'Podaj nam Twój adres gmail:',
                            label: 'GMail',
                            placeholder: 'jankowalski@gmai.com'
                        }
                    ]
                },
                {
                    type: 'Radio',
                    text: 'Nie',
                    value: 'Nie',
                    content: null
                }
            ]
        },
        {
            title: 'Do kogo chcesz przede wszystkim kierować swoje produkty, zcharakteryzuj docelowego klienta (np. płeć, wiek, lokalizacja, dochód, zainteresowania, przyzwyczajenia):',
            content: [
                {
                    type: 'TextArea',
                    text: 'Charakterystyka docelowego klienta:',
                    label: 'Charakterystyka',
                    placeholder: 'Kobiety powyżej 30 roku życia...'
                }
            ]
        },
        {
            title: 'Czy masz już konta na innych portalach (Market Place’ach) z którymi chciałbyś się zintegrować:',
            content: [
                {
                    type: 'Radio',
                    text: 'Nie',
                    value: 'Nie',
                    content: null
                },
                {
                    type: 'Radio',
                    text: 'Tak',
                    value: 'Tak',
                    content: [
                        {
                            type: 'Checkbox',
                            stateKey: 'facebook',
                            text: 'Facebook',
                            value: 'facebook',
                            content: [
                                {
                                    type: 'TextField',
                                    stateKey: 'facebookEMail',
                                    text: 'Podaj swój mail do logowania:',
                                    label: 'e-mail',
                                    placeholder: 'jankowalski@xyz.com'
                                }
                            ]
                        },
                        {
                            type: 'Checkbox',
                            stateKey: 'allegro',
                            text: 'Allegro',
                            value: 'Allegro',
                            content: null
                        },
                        {
                            type: 'Checkbox',
                            stateKey: 'opineo',
                            text: 'Opineo',
                            value: 'Opineo',
                            content: null
                        },
                        {
                            type: 'Checkbox',
                            stateKey: 'ceneo',
                            text: 'Ceneo',
                            value: 'Ceneo',
                            content: null
                        },
                        {
                            type: 'Checkbox',
                            stateKey: 'ceneoZaufaneOpinie',
                            text: 'Ceneo Zaufane opinie',
                            value: 'Ceneo Zaufane opinie',
                            content: null
                        },
                        {
                            type: 'Checkbox',
                            stateKey: 'amazon',
                            text: 'Amazon',
                            value: 'Amazon',
                            content: null
                        },
                        {
                            type: 'Checkbox',
                            stateKey: 'ebay',
                            text: 'Ebay',
                            value: 'Ebay',
                            content: null
                        },
                        {
                            type: 'TextField',
                            stateKey: 'inne',
                            label: 'inne',
                            text: 'Inne:',
                            placeholder: ''
                        }
                    ]
                }
            ]
        },
        {
            title: 'Czy masz system mailingowy (lub Marketing Automation) z którym chciałbyś się zintegrować:',
            content: [
                {
                    type: 'Radio',
                    text: 'Nie - poproszę o rekomendację',
                    value: 'Nie',
                    content: null
                },
                {
                    type: 'Radio',
                    text: 'Tak',
                    value: 'Tak',
                    content: [
                        {
                            type: 'Radio',
                            text: 'Mailchimp',
                            value: 'Mailchimp',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'Freshmail',
                            value: 'Freshmail',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'GetResponse',
                            value: 'GetResponse',
                            content: null
                        },
                        {
                            type: 'Radio',
                            text: 'Sales Manago',
                            value: 'Sales Manago',
                            content: null
                        },
                        {
                            type: 'TextField',
                            text: 'Inny:',
                            label: 'System',
                            placeholder: 'Twój system mailingowy...'
                        }
                    ]
                }
            ]
        },
        {
            title: {
                bold: 'Dziękujemy! ',
                regular: 'Wiemy już wszystko. Podaj nam swoje dane kontaktowe a nasz konsultant skontaktuje się z Tobą w celu przedstawienia zasad współpracy.'   
            },
            content: [
                {
                    type: 'Summary'
                }
            ]
        }
    ]
};

export const textsStatic = {
    summary: {
        fields: {
            name: 'Imię:',
            phone: 'Telefon',
            email: 'email'
        },
        agreement: {
            text: 'Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z ',
            link: {
                text: 'polityką prywatności',
                url: 'https://heraldbee.com/privacy-policy/'
            }
        },
        buttons: {
            finish: 'Poznaj szczegóły współpracy',
            back: 'Wstecz'
        } 
    },
};