// Core
import React from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { motion } from 'framer-motion';
import QuestionElement from '../QuestionElement';

// Stylesheet
import styles from './index.module.scss';

const ExpandableQuestionDetails = ({ fields, onChangeInner, onChangeInnerText, onChangeExtra, stateValueInner, stateValueInnerText, stateValueExtra, isOpen, multifield }) => {
    const framerContainer = {
        open: {
            height: 'auto',
            transition: {
                staggerChildren: 0.07, 
                delayChildren: 0.05,
                duration: .2,
            }
        },
        closed: {
            height: 0,
            transition: {
                delay: .2,
                staggerChildren: 0.05,
                staggerDirection: -1,
                duration: .3,
            }
        }
    };

    const framerItem = {
        open: {
            opacity: 1,
            transition: {
                duration: .15,
            }
        },
        closed: {
            opacity: 0,
            transition: {
                duration: .075
            }
        }
    };

    const adjustedState = (type, stateKey) => {
        if (type === 'TextField' && ( stateValueInnerText || stateValueInnerText === '')) {
            return stateValueInnerText;
        } else {
            if (stateKey) {
                return stateValueInner[stateKey];
            } else {
                return stateValueInner;
            }
        }
    };

    const adjustedOnChangeInner = (type, stateKey) => {
        if (type === 'TextField' && onChangeInnerText) {
            return onChangeInnerText;
        } else {
            if (stateKey) {
                return onChangeInner(stateKey);
            } else {
                return onChangeInner;
            }
        }
    }

    return (
        <motion.ul 
            initial={false}
            animate={isOpen ? 'open' : 'closed'}
            variants={framerContainer}
            className={styles.container}
        >
            {
                fields.map((field, i) =>
                    <motion.li key={`${field.value}${i}`} variants={framerItem}>
                        <QuestionElement
                            onChange={multifield ? onChangeInner(field.stateKey || 'selection') : adjustedOnChangeInner(field.type, field.stateKey)}
                            stateValue={multifield ? stateValueInner[field.stateKey] : adjustedState(field.type, field.stateKey)}
                            questionField={field}
                        />
                        {
                            field.content && field.content.map((subField, iSub) => 
                                <div key={`${subField.type}${iSub}`} className={styles.item}>
                                    <QuestionElement
                                        onChange={onChangeExtra(subField.stateKey)}
                                        stateValue={stateValueExtra}
                                        questionField={subField}
                                    />
                                </div>
                            )
                        }
                    </motion.li>
                )
            }
        </motion.ul>
    );
};

ExpandableQuestionDetails.propTypes = {
    fields: PropTypes.array,
    onChangeInner: PropTypes.func,
    stateValueInner: PropTypes.any,
    isOpen: PropTypes.bool
};

export default ExpandableQuestionDetails;