export const colorPalette = {
    // App colors pallete
    primaryYellow: '#FFD742',
    primaryYellowRGB: '255, 215, 66',
    secondaryYellow: '#FAE183',

    primaryOrange: '#D4902C',
    primaryOrangeRGB: '212,144,44',
    secondaryOrange: '#EDBF15',
    secondaryOrangeRGB: '237,191,21',

    primaryBrown: '#54281F',
    primaryBrownRGB: '84, 40, 31',

    primaryGray: '#707070',
    secondaryGray: '#f8f8f8',
    lightGray: '#FAFAFA',
    lightGray2: '#F2F2F2',

    black: '#000',
    blackRGB: '0,0,0',

    white: '#fff',
    whiteRGB: '255,255,255',
    tintedWhite: '#eeeae9',

    // App general
    successColor: ' #00a255',

    errorColor: '#b32020',
    errorRed: '#b00020',
    errorIcon: '#ff4747',

    // App components
    infoButtonGrey: '#a9938e'
};

export const typography = {
    body: {
        fontSize: '14px',
        paragraphMd: '12px',
    },
    paragraphSm: '10px',
    paragraphLg: '16px',
    paragraphMd: '12px',
    paragraphXl: '20px',
    headingSm: '18px',
    headingSm2: '14px'
};

export const breakpoints = {
    xs: '320px',
    sm: '576px',
    sl: '600px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px'
};

export const acceptedPaymentMethods = {
    paymentCard: 'Payment Card',
    payPal: 'PayPal'
};

export const spinnerStyle = {
    height: "100vh",
    display: "flex",
    alignItems: "center"
};