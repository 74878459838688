// Core
import React from 'react';
import { useHistory } from 'react-router-dom';

// Custom Components
import SummaryPage from '../../../../pages/SummaryPage';

const Summary = ({ state, details }) => {
    let history = useHistory();

    const onClickLeft = () => {
        history.push('/withShop/q7');
    };

    return (
        <SummaryPage
            designation="withShop"
            onClickLeft={onClickLeft}
            state={state}
            details={details}
        />
    );
};

export default Summary;