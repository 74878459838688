// Core
import React from 'react';
import PropTypes from 'prop-types';

// Custom Components
import TextButton from '../../Button/TextButton';

// Stylesheet
import styles from './index.module.scss';

const ButtonRowDouble = ({ exception, rightDisabled, onClickLeft, onClickRight, buttonTextLeft, buttonTextRight }) =>
    <div className={`${exception ? styles.exception : styles.container} level`}>
        <TextButton onClick={onClickLeft} type="secondary">{buttonTextLeft}</TextButton>
        <TextButton disabled={rightDisabled} onClick={onClickRight}>{buttonTextRight}</TextButton>
    </div>

ButtonRowDouble.propTypes = {
    onClickLeft: PropTypes.func,
    onClickRight: PropTypes.func,
    buttonTextLeft: PropTypes.string,
    buttonTextRight: PropTypes.string
};

export default ButtonRowDouble;