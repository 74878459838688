// Core
import React from 'react';
import PropTypes from 'prop-types';

// Custom Components
import TextField from '..';

// Stylesheet
import styles from '../../index.module.scss';

const TextRadio = ({ children, onChange, placeholder, label, value, ...rest }) =>
    <TextField onChange={onChange} placeholder={placeholder} label={label} value={value || ''} {...rest}>
        <p className={`${styles.text} is-size-6`}>{children}</p>
    </TextField>

TextRadio.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    children: PropTypes.string,
};

TextRadio.defaultProps = {
    onChange: () => console.log('TextField Changed'),
    placeholder: 'placeholder',
    label: 'label',
    children: 'Text Field'
};

export default TextRadio;