// Core
import { connect } from 'react-redux';
// Actions
import { setSelection, setInner } from '../../../../ducks/withShop/Methods';

import Component from './Component';

const mapStateToProps = state => ({
    selection: state.withShop.q4.selection,
    inner: state.withShop.q4.inner
});

const mapDispatchToProps = {
    setSelection,
    setInner
};
const Q4 = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Q4;