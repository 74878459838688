// Actions

const SET_WITH_SHOPQUESTION_SELECTION_VALUE = 'SET_WITH_SHOPQUESTION_SELECTION_VALUE';
const SET_WITH_SHOP_QUESTION_SELECTION_VALUE_BY_KEY = 'SET_WITH_SHOP_QUESTION_SELECTION_VALUE_BY_KEY';
const SET_WITH_SHOPQUESTION_INNER_VALUE = 'SET_WITH_SHOPQUESTION_INNER_VALUE';
const SET_WITH_SHOPQUESTION_EXTRA_VALUE = 'SET_WITH_SHOPQUESTION_EXTRA_VALUE';

// Reducer

const initialState = {
    q1: {
        title: 'Na jakiej platformie sklepowej masz opartą sprzedaż:',
        selection: null,
    },
    q2: {
        title: 'Na jakich obszarach geograficznych chciałbyś działać (pamietaj o konieczności tłumaczeń produktów i odpowiedniej obsługi klienta):',
        selection: {
            region: null,
            kraj: null
        }
    },
    q3: {
        title: 'Czy posiadasz już konta Social Media?',
        selection: null,
        inner: {
            facebook: null,
            instagram: null,
            inne: null
        }
    },
    q4: {
        title: 'Czy posiadasz konto Google (adres poczty w obrębie domeny gmail) na którym chciałbyś mieć skonfigurowane narzędzia analityczne Google tj. Google Analytics, Google Search Console, Google Ads, Google Merchant Center?',
        selection: null,
        inner: {
            gmail: null
        }
    },
    q5: {
        title: 'Do kogo chcesz przede wszystkim kierować swoje produkty, zcharakteryzuj docelowego klienta (np. płeć, wiek, lokalizacja, dochód, zainteresowania, przyzwyczajenia):',
        selection: {
            charakterystyka: null
        }
    },
    q6: {
        title: 'Czy masz już konta na innych portalach (Market Place’ach) z którymi chciałbyś się zintegrować:',
        selection: null,
        inner: {
            facebook: null,
            allegro: null,
            opineo: null,
            ceneo: null,
            ceneoZaufaneOpinie: null,
            amazon: null,
            ebay: null,
            inne: null
        },
        extra: {
            facebookEMail: null
        }
    },
    q7: {
        title: 'Czy masz system mailingowy (lub Marketing Automation) z którym chciałbyś się zintegrować:',
        selection: null,
        inner: {
            selection: null,
            inne: null
        }
    },
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_WITH_SHOPQUESTION_SELECTION_VALUE:
            return {
                ...state,
                [action.question]: {
                    ...state[action.question],
                    selection: action.value
                }
            };

        case SET_WITH_SHOP_QUESTION_SELECTION_VALUE_BY_KEY:
            return {
                ...state,
                [action.question]: {
                    ...state[action.question],
                    selection: {
                        ...state[action.question].selection,
                        [action.selectionKey]: action.value
                    }
                }
            };

        case SET_WITH_SHOPQUESTION_INNER_VALUE:
            return {
                ...state,
                [action.question]: {
                    ...state[action.question],
                    inner: {
                        ...state[action.question].inner,
                        [action.innerKey]: action.value
                    }
                }
            }

        case SET_WITH_SHOPQUESTION_EXTRA_VALUE:
            return {
                ...state,
                [action.question]: {
                    ...state[action.question],
                    extra: {
                        ...state[action.question].extra,
                        [action.extraKey]: action.value
                    }
                }
            }
        
        default: return state;
    }
};
export default reducer;

// Action Creators

export const actionWithShopSetQuestionSelectionValue = (question, value) => ({ type: SET_WITH_SHOPQUESTION_SELECTION_VALUE, question, value });
export const actionWithShopSeqQuestionSelectionValueByKey = (question, selectionKey, value) => ({ type: SET_WITH_SHOP_QUESTION_SELECTION_VALUE_BY_KEY, question, selectionKey, value });
export const actionWithShopSetQuestionInnerValue = (question, innerKey, value) => ({ type: SET_WITH_SHOPQUESTION_INNER_VALUE, question, innerKey, value });
export const actionWithShopSetQuestionExtraValue = (question, extraKey, value) => ({ type: SET_WITH_SHOPQUESTION_EXTRA_VALUE, question, extraKey, value });

