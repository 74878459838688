// Core
import React from 'react';
import { useHistory } from 'react-router-dom';

// Custom Components
import QuestionPage from '../../../../../pages/QuestionPage';
import Question from '../../../../../components/primitive/Question';
import QuestionElement from '../../../../../components/complex/QuestionElement';
import ExpandableQuestionDetails from '../../../../../components/complex/ExpandableQuestionDetails';

const Q3 = ({ selection, inner, texts, setSelection, setInner }) => {
    let history = useHistory();

    const onClickNavigate = direction => () => {
        history.push(direction === 'back' ? '/withShop/q2' : '/withShop/q4');
    };

    const onChange = e => {
        setSelection('q3', e.target.value);
    };

    const onChangeInner = key => e => {
        setInner('q3', key, e.target.value)
    };

    return (
        <QuestionPage onClickLeft={onClickNavigate('back')} onClickRight={onClickNavigate()}>
            <Question
                title={texts.title}
                questionNumber={3}
                questionTotal={7}
            >
                {
                    texts.content.map((field, i) =>
                        <div key={i}>
                            <QuestionElement
                                questionField={field}
                                stateValue={selection}
                                onChange={onChange}
                            />
                            {
                                field.content && field.content.length > 0
                                    ? (
                                        <ExpandableQuestionDetails
                                            multifield
                                            fields={field.content}
                                            onChangeInner={onChangeInner}
                                            stateValueInner={inner}
                                            isOpen={selection === field.value}
                                        />
                                    ) : (
                                        null
                                    )
                            }
                        </div>
                    )
                }
            </Question>
        </QuestionPage>
    );
};

export default Q3;