// Core
import React from 'react';
import { useHistory } from 'react-router-dom';

// Custom Components
import QuestionPage from '../../../../../pages/QuestionPage';
import Question from '../../../../../components/primitive/Question';
import QuestionElement from '../../../../../components/complex/QuestionElement';

const Q3 = ({ selection, texts, setSelection }) => {
    let history = useHistory();

    const onClickNavigate = direction => () => {
        history.push(direction === 'back' ? '/noShop/q2' : '/noShop/q4');
    };

    const onChange = e => {
        setSelection('q3', e.target.value);
    };

    return (
        <QuestionPage onClickLeft={onClickNavigate('back')} onClickRight={onClickNavigate()}>
            <Question
                title={texts.title}
                questionNumber={3}
                questionTotal={14}
            >
                {
                    texts.content.map((field, i) =>
                        <div key={i}>
                            <QuestionElement
                                questionField={field}
                                stateValue={selection}
                                onChange={onChange}
                            />
                        </div>
                    )
                }
            </Question>
        </QuestionPage>
    );
};

export default Q3;