// Core
import { connect } from 'react-redux';
// Actions
import { setSelectionByKey } from '../../../../ducks/withShop/Methods';

import Component from './Component';

const mapStateToProps = state => ({
    selection: state.withShop.q2.selection,
});

const mapDispatchToProps = {
    setSelectionByKey,
};

const Q2 = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Q2;