// Core
import React from 'react';
import PropTypes from 'prop-types';

// Custom Components
import Radio from '..';

// Stylesheet
import styles from '../index.module.scss';

const TextRadio = ({ children, onChange, checked, value, ...rest }) =>
    <Radio onChange={onChange} checked={checked} value={value} {...rest}>
        <p className={`${styles.text} is-size-6`}>{children}</p>
    </Radio>

TextRadio.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    children: PropTypes.string,
    cssClass: PropTypes.string
};

TextRadio.defaultProps = {
    checked: false,
    onChange: () => console.log('Radio Changed'),
    value: 'foobar',
    children: 'Radio'
};

export default TextRadio;