// Core
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

// Third-Party Modules
import { Helmet } from 'react-helmet';

// Custom Components
import MainRouter from './routers/MainRouter';

// Stylesheet
import './App.scss';
import './styles/index.scss';

function App() {
  return (
    <Router>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Heraldbee User Introduction Form</title>
        <meta name="description" content="Introductory form allowing the user to apply for direct Heraldbee assistance in e-commerce creation, automation and marketing management"/>
      </Helmet>
      <MainRouter />
    </Router>
  );
};

export default App;
