// Core
import React from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { motion } from 'framer-motion';

// Custom Components
import QuestionElement from '../../complex/QuestionElement';

// Stylesheet
import styles from './index.module.scss';


const ImageRadioGroup = ({ fieldGroup, stateValue, onChange }) => {
    const framerContainer = {
        hidden: { opacity: 0 },
        show: { 
            opacity: 1,
            transition: {
                staggerChildren: .15,
            }
        }
    };
    const framerItem = {
        hidden: { 
            opacity: 0,
            transition: {
                duration: .5,
            }
        },
        show: { 
            opacity: 1,
            transition: {
                duration: .5,
            }
        }
    };

    return (
        <motion.div className={styles.container}>
            <motion.ul 
                className={styles.containerInner}
                variants={framerContainer}
                initial="hidden"
                animate="show"
            >
                {
                    fieldGroup.map((field, i) => 
                        <motion.li
                            key={i}
                            variants={framerItem}
                        >
                            <QuestionElement
                                questionField={field}
                                stateValue={stateValue}
                                onChange={onChange}
                                isOpen={false}
                            />
                        </motion.li>
                    )
                }
            </motion.ul>
        </motion.div>
    );
};

ImageRadioGroup.propTypes = {
    fieldGroup: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            header: PropTypes.string,
            description: PropTypes.string,
            img: PropTypes.string,
            value: PropTypes.string,
            link: {
                text: PropTypes.string,
                url: PropTypes.string
            }
        })
    ),
    stateValue: PropTypes.any,
    onChange: PropTypes.func
};

export default ImageRadioGroup;