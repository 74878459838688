// Core
import React from 'react';
import { useHistory } from 'react-router-dom';

// Custom Components
import QuestionPage from '../../../../../pages/QuestionPage';
import Question from '../../../../../components/primitive/Question';
import ImageRadioGroup from '../../../../../components/complex/ImageRadioGroup';

const Q1 = ({ selection, texts, setSelection }) => {
    let history = useHistory();

    const onClickNavigate = direction => () => {
        history.push(direction === 'back' ? '/noShop' : '/noShop/q2');
    };

    const onChange = e => {
        setSelection('q1', e.target.value);
    };
    
    return (
        <QuestionPage onClickLeft={onClickNavigate('back')} onClickRight={onClickNavigate()}>
            <Question
                title={texts.title}
                questionNumber={1}
                questionTotal={14}
                fullWidth
            >
                <ImageRadioGroup
                    fieldGroup={texts.content}
                    stateValue={selection}
                    onChange={onChange}
                />
            </Question>
        </QuestionPage>
    );
};

export default Q1;