
import { makeStyles } from "@material-ui/core/styles";
import { colorPalette } from '../../../styles';

export const useStylesTextField = makeStyles(theme => ({
    root: {
        minWidth: "200px",
		borderRadius: "4px",
        backgroundColor: "white",
        
        "& .MuiInputLabel-shrink": {
            color: colorPalette.primaryGray
        },

        '& label.Mui-focused': {
            color: colorPalette.primaryOrange,
        },

        '& .MuiInput-underline:after': {
        borderBottomColor: colorPalette.primaryOrange,
        },

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colorPalette.primaryGray,
            },
            '&:hover fieldset': {
                borderColor: colorPalette.primaryOrange,
            },
            '&.Mui-focused fieldset': {
                borderColor: colorPalette.primaryOrange,
            },
        },
	},
}));

export const useStylesTextArea = makeStyles(theme => ({
    root: {
        minWidth: "200px",
        margin: "20px 0 0 20px",
		borderRadius: "4px",
        backgroundColor: "white",
        
        "& .MuiInputLabel-shrink": {
            color: colorPalette.primaryGray
        },

        '& label.Mui-focused': {
            color: colorPalette.primaryOrange,
        },

        '& .MuiInput-underline:after': {
        borderBottomColor: colorPalette.primaryOrange,
        },

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colorPalette.primaryGray,
            },
            '&:hover fieldset': {
                borderColor: colorPalette.primaryOrange,
            },
            '&.Mui-focused fieldset': {
                borderColor: colorPalette.primaryOrange,
            },
        },
	},
}));

export const useStylesSelect = makeStyles(theme => ({
    root: {
        minWidth: "200px",
		borderRadius: "4px",
        backgroundColor: "white",
        
        "& .MuiInputLabel-shrink": {
            color: colorPalette.primaryGray
        },

        '& label.Mui-focused': {
            color: colorPalette.primaryOrange,
        },

        '& .MuiInput-underline:after': {
        borderBottomColor: colorPalette.primaryOrange,
        },

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colorPalette.primaryGray,
            },
            '&:hover fieldset': {
                borderColor: colorPalette.primaryOrange,
            },
            '&.Mui-focused fieldset': {
                borderColor: colorPalette.primaryOrange,
            },
        },
	},
}));