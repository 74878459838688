import { 
    actionNoShopSetQuestionSelectionValue,
    actionNoShopSeqQuestionSelectionValueByKey,
    actionNoShopSetQuestionExtraValue,
    actionNoShopSetQuestionInnerValue
} from '..';

export const setSelection = (question, value) => actionNoShopSetQuestionSelectionValue(question, value);

export const setSelectionByKey = (question, selectionKey, value) => actionNoShopSeqQuestionSelectionValueByKey(question, selectionKey, value);

export const setInner = (question, innerKey, value) => actionNoShopSetQuestionInnerValue(question, innerKey, value);

export const setExtra = (question, extraKey, value) => actionNoShopSetQuestionExtraValue(question, extraKey, value);