// Core
import React from 'react';
import PropTypes from 'prop-types';

// Custom Components
import TextButton from '../../Button/TextButton';

// Stylesheet
import styles from './index.module.scss';

const ButtonRowSingle = ({ onClick, buttonText, disabled }) =>
    <div className={`${styles.container} level`}>
        <TextButton disabled={disabled} onClick={onClick}>{buttonText}</TextButton>
    </div>

ButtonRowSingle.propTypes = {
    onClick: PropTypes.func,
    buttonText: PropTypes.string
};

export default ButtonRowSingle;