// Core
import React from 'react';
import PropTypes from 'prop-types';

// Custom Components
import Button from '..';

const TextButton = ({ children, onClick, disabled, ...rest }) =>
    <Button onClick={onClick} disabled={disabled} {...rest}>
        <p className={`is-size-6`}>{children}</p>
    </Button>

TextButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

TextButton.defaultProps = {
    onClick: () => console.log('Button Clicked'),
    disabled: false,
    text: 'Buchildren'
};
export default TextButton;