// Core
import { connect } from 'react-redux';
// Actions
import { setSelectionByKey, setInner } from '../../../../ducks/noShop/Methods';

import Component from './Component';

const mapStateToProps = state => ({
    selection: state.noShop.q2.selection,
    inner: state.noShop.q2.inner
});

const mapDispatchToProps = {
    setSelectionByKey,
    setInner
};

const Q2 = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Q2;