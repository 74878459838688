import { makeStyles } from "@material-ui/core/styles";
import { colorPalette } from '../../../styles';

export const useStylesBase = makeStyles(theme => ({
    root: {
        color: colorPalette.primaryGray,

        "&:hover": {
            backgroundColor: `rgba(${colorPalette.primaryOrangeRGB}, .2) !important`
        },
        "& .MuiTouchRipple-root": {
			color: colorPalette.white
        },
    },
    checked : {
        "& .MuiSvgIcon-root": {
            color: colorPalette.primaryOrange
        },
    }
}));