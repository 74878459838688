// Core
import React from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { TextField as MaterialTextField } from '@material-ui/core';

// Stylesheet
import styles from '../index.module.scss';
import { useStylesTextField } from '../index.material';

const TextField = ({ placeholder, label, value, children, forcedFormat, ...rest }) => {

    const materialStyles = useStylesTextField();

    return (
        <div className={styles.containerField}>
            <div className={`${styles.contentField} ${forcedFormat ? styles.contentFieldForced : null}`}>
                {children}
            </div>
            <MaterialTextField
                className={materialStyles.root}
                placeholder={placeholder}
                label={label}
                value={value || ''}
                variant="outlined"
                {...rest}
            />
        </div>
    );
};

TextField.propTypes = {
    placeholder: PropTypes.node,
    label: PropTypes.node,
    value: PropTypes.any,
    onChange: PropTypes.func,
    children: PropTypes.node
};

TextField.defaultProps = {
    placeholder: 'placeholder',
    label: 'label',
    onChange: () => console.log('TextField change'),
    children: 'Text Field'
};

export default TextField;