// Core
import React from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { Radio as MaterialRadio } from '@material-ui/core';

// Stylesheet
import styles from './index.module.scss';
import { useStylesBase } from './index.material';

const Radio = ({ checked, onChange, value, children, cssClass, ...rest }) => {
    
    const materialStyles = useStylesBase();

    return (
        <span className={cssClass ? cssClass : styles.container}>
            <MaterialRadio
                checked={checked}
                onChange={onChange}
                value={value} 
                inputProps={{ 'aria-label': `${value}`}}
                name="radio-button"
                classes={{root: materialStyles.root, checked: materialStyles.checked}}
                {...rest}
            />
            <div className={styles.content}>
                {children}
            </div>
        </span>
    );
};

Radio.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    children: PropTypes.node,
    cssClass: PropTypes.string
};

Radio.defaultProps = {
    checked: false,
    onChange: () => console.log('Radio Changed'),
    value: 'foobar',
};

export default Radio;