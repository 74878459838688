// React
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
// Methods
import { setDetailsValueByKey } from '../../../ducks/details/Methods';

import Component from './Component';

const mapStateToProps = state => ({
    details: state.details
});

const mapDispatchToProps = {
    setDetailsValueByKey
};

const Intro = withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));

export default Intro;