// Core
import React from 'react';
import { Route } from 'react-router-dom';

// Custom Modules
import { textsNoShop } from '../../texts';

// Custom Components
import Intro from '../../implementedViews/noShop/Intro/index.js';
import { Q1, Q2, Q3, Q4, Q5, Q6, Q7, Q8, Q9, Q10, Q11, Q12, Q13, Q14 } from '../../implementedViews/noShop/questions';
import Summary from '../../implementedViews/noShop/Summary/index.js';

const NoShopRouter = () => {
    return (
        <>
            <Route exact path="/noShop">
                <Intro />
            </Route>

            <Route path="/noShop/q1">
                <Q1 texts={textsNoShop.questions[0]}/>
            </Route>

            <Route path="/noShop/q2">
                <Q2 texts={textsNoShop.questions[1]}/>
            </Route>

            <Route path="/noShop/q3">
                <Q3 texts={textsNoShop.questions[2]}/>
            </Route>

            <Route path="/noShop/q4">
                <Q4 texts={textsNoShop.questions[3]}/>
            </Route>

            <Route path="/noShop/q5">
                <Q5 texts={textsNoShop.questions[4]}/>
            </Route>

            <Route path="/noShop/q6">
                <Q6 texts={textsNoShop.questions[5]}/>
            </Route>

            <Route path="/noShop/q7">
                <Q7 texts={textsNoShop.questions[6]}/>
            </Route>
            
            <Route path="/noShop/q8">
                <Q8 texts={textsNoShop.questions[7]}/>
            </Route>

            <Route path="/noShop/q9">
                <Q9 texts={textsNoShop.questions[8]}/>
            </Route>

            <Route path="/noShop/q10">
                <Q10 texts={textsNoShop.questions[9]}/>
            </Route>

            <Route path="/noShop/q11">
                <Q11 texts={textsNoShop.questions[10]}/>
            </Route>

            <Route path="/noShop/q12">
                <Q12 texts={textsNoShop.questions[11]}/>
            </Route>

            <Route path="/noShop/q13">
                <Q13 texts={textsNoShop.questions[12]}/>
            </Route>

            <Route path="/noShop/q14">
                <Q14 texts={textsNoShop.questions[13]}/>
            </Route>

            <Route path="/noShop/summary">
                <Summary />
            </Route>
        </>
    );
};

export default NoShopRouter;