// Core
import React from 'react';
import { useHistory } from 'react-router-dom';

// Custom Components
import QuestionPage from '../../../../../pages/QuestionPage';
import Question from '../../../../../components/primitive/Question';
import QuestionElement from '../../../../../components/complex/QuestionElement';

const Q5 = ({ selection, texts, setSelectionByKey }) => {
    let history = useHistory();

    const onClickNavigate = direction => () => {
        history.push(direction === 'back' ? '/withShop/q4' : '/withShop/q6');
    };

    const onChange = key => e => {
        setSelectionByKey('q5', key, e.target.value);
    };

    return (
        <QuestionPage onClickLeft={onClickNavigate('back')} onClickRight={onClickNavigate()}>
            <Question
                title={texts.title}
                questionNumber={5}
                questionTotal={7}
            >
                {
                    texts.content.map((field, i) =>
                        <div key={i}>
                            <QuestionElement
                                questionField={field}
                                stateValue={selection[field.label.toLowerCase()]}
                                onChange={onChange(field.label.toLowerCase())}
                            />
                        </div>
                    )
                }
            </Question>
        </QuestionPage>
    );
};

export default Q5;