// Actions

const SET_DETAILS_VALUE_BY_KEY = 'SET_DETAILS_VALUE_BY_KEY';

// Reducer
const initialState = {
    name: null,
    phone: null,
    email: null
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_DETAILS_VALUE_BY_KEY:
            return {
                ...state,
                [action.key]: action.value
            };

        default: return state;
    };
};

export default reducer;

// Action Creators
export const actionSetDetailsValueByKey = (key, value) => ({ type: SET_DETAILS_VALUE_BY_KEY, key, value });
