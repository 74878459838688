// Core
import React from 'react';
import PropTypes from 'prop-types';

// Custom Components
import TextRadio from '../../primitive/Radio/TextRadio';
import ImageRadio from '../../primitive/ImageRadio';
import TextCheckbox from '../../primitive/Checkbox/TextCheckbox';
import TextSelect from '../../primitive/TextInputs/Select/TextSelect';
import TextTextArea from '../../primitive/TextInputs/TextArea/TextTextArea';
import TextTextField from '../../primitive/TextInputs/TextField/TextTextField';

const QuestionElement = ({ onChange, stateValue, questionField }) => {
    switch (questionField.type) {
        case 'Radio':
            return (
                <TextRadio
                    onChange={onChange}
                    checked={stateValue === questionField.value}
                    value={questionField.value}
                >
                    {questionField.text}
                </TextRadio>
            );
        case 'RadioField':
            return (
                <ImageRadio
                    header={questionField.header}
                    description={questionField.description}
                    imgs={questionField.imgs}
                    link={questionField.link}
                    checked={stateValue === questionField.value}
                    onChange={onChange}
                    value={questionField.value}
                />
            );
        case 'Checkbox':
            return (
                <TextCheckbox
                    onChange={onChange}
                    checked={stateValue === questionField.value}
                    value={questionField.value}
                >
                    {questionField.text}
                </TextCheckbox>
            );
        case 'Select':
            return (
                <TextSelect
                    onChange={onChange}
                    label={questionField.label}
                    value={stateValue || ''}
                    optionList={questionField.optionList}
                >
                    {questionField.text}
                </TextSelect>
            );
        case 'TextArea':
            return (
                <TextTextArea
                    onChange={onChange}
                    placeholder={questionField.placeholder}
                    label={questionField.label}
                    value={stateValue || null}
                >
                    {questionField.text}
                </TextTextArea>
            )
        case 'TextField':
            return (
                <TextTextField
                    onChange={onChange}
                    placeholder={questionField.placeholder}
                    label={questionField.label}
                    value={stateValue || null}
                    forcedFormat={questionField.forced}
                >
                    {questionField.text}
                </TextTextField>
            )
        default:
            return null;
    };
};

QuestionElement.propTypes = {
    onChange: PropTypes.func,
    stateValue: PropTypes.any,
    questionField: PropTypes.shape({
        type: PropTypes.string.isRequired,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.any,
        text: PropTypes.string,
    })
};

QuestionElement.defaultProps = {
    onChange: () => console.log('change'),
    stateValue: false,
    questionField: {
        type: 'Radio',
        value: 'foobar',
        text: 'Radio'
    }
};

export default QuestionElement;