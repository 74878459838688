// Core
import React from 'react';
import PropTypes from 'prop-types';

// Custom Components
import ButtonRowDouble from '../../components/primitive/ButtonRow/ButtonRowDouble';

// Stylesheet
import styles from './index.module.scss';

const IntroPage = ({ onClickLeft, onClickRight, children }) => {
    return (
        <article className={`${styles.container}`}>
            {children}
            <div className={`${styles.buttons}`}>
                <ButtonRowDouble
                    onClickLeft={onClickLeft}
                    buttonTextLeft={"Wstecz"}
                    onClickRight={onClickRight}
                    buttonTextRight="Dalej"
                />
            </div>
        </article>
    );
};

IntroPage.propTypes = {
    onClickLeft: PropTypes.func,
    onClickRight: PropTypes.func,
    children: PropTypes.node
};

export default IntroPage;