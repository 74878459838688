// Core
import React from 'react';
import { Route } from 'react-router-dom';

// Custom Modules
import { textsWithShop } from '../../texts';

// Custom Components
import Intro from '../../implementedViews/withShop/Intro/index.js';
import { Q1, Q2, Q3, Q4, Q5, Q6, Q7 } from '../../implementedViews/withShop/questions';
import Summary from '../../implementedViews/withShop/Summary/index.js';

const NoShopRouter = () => {
    return (
        <>
            <Route exact path="/withShop">
                <Intro />
            </Route>

            <Route path="/withShop/q1">
                <Q1 texts={textsWithShop.questions[0]}/>
            </Route>

            <Route path="/withShop/q2">
                <Q2 texts={textsWithShop.questions[1]}/>
            </Route>

            <Route path="/withShop/q3">
                <Q3 texts={textsWithShop.questions[2]}/>
            </Route>

            <Route path="/withShop/q4">
                <Q4 texts={textsWithShop.questions[3]}/>
            </Route>

            <Route path="/withShop/q5">
                <Q5 texts={textsWithShop.questions[4]}/>
            </Route>

            <Route path="/withShop/q6">
                <Q6 texts={textsWithShop.questions[5]}/>
            </Route>

            <Route path="/withShop/q7">
                <Q7 texts={textsWithShop.questions[6]}/>
            </Route>

            <Route path="/withShop/summary">
                <Summary />
            </Route>
        </>
    );
};

export default NoShopRouter;