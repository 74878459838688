// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import axios from 'axios';

// Custom Modules
import { textsStatic, textsNoShop, textsWithShop } from '../../texts';

// Custom Components
import ButtonRowDouble from '../../components/primitive/ButtonRow/ButtonRowDouble';

// Stylesheet
import styles from './index.module.scss';

const SummaryPage = ({ designation, onClickLeft, state, details }) => {
    const title = designation === 'noShop' ? textsNoShop.questions[14].title : textsWithShop.questions[7].title;

    const [ error, setError ] = useState(null);
    const [ success, setSuccess ] = useState(null);

    const onClickRight = async () => {
        const areFieldsFilled = details.name && details.phone && details.email;
        console.dir(state);
        if (!areFieldsFilled) {
            setError('By móc wysłać ankietę pola Imię, Telefon i Email nie mogą być puste');
        } else {
            const payload = JSON.stringify({
                dane: {
                    imie: details.name,
                    telefon: details.phone,
                    email: details.email
                },
                ankieta: state,
            });

            try {
                const response = await axios.post(
                    'https://formularz.heraldbee.com/submit',
                    payload,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                );

                if (response) {
                    setSuccess('Dziękujemy za wysłanie ankiety, niedługo skontaktujemy się z Tobą!');
                };
            } catch(error) {
                if (error) {
                    setError(error.message);
                };
            }
            

        };
    };


    return (
        <article className={`${styles.container}`}>
            <header className={styles.header}>
                <p className={`${styles.title} is-size-5 is-family-secondary is-size-4-desktop`}><strong>{title.bold}</strong>{title.regular}</p>
            </header>
            <div className={styles.error}>
                <p className={`${success ? styles.success : null} is-size-7`}>{error || success}</p>
            </div>
            <div className={styles.buttons}>
                <ButtonRowDouble
                    exception
                    onClickLeft={onClickLeft}
                    buttonTextLeft={textsStatic.summary.buttons.back}
                    onClickRight={onClickRight}
                    buttonTextRight={textsStatic.summary.buttons.finish}
                />
            </div>
        </article>
    );
};

SummaryPage.propTypes = {
    onClickLeft: PropTypes.func,
    onClickRight: PropTypes.func,
    children: PropTypes.node
};

export default SummaryPage;