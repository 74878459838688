// Core
import React from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { Checkbox as MaterialCheckbox } from '@material-ui/core';

// Stylesheet
import styles from './index.module.scss';
import { useStylesBase } from './index.material';

const Checkbox = ({ children, checked, onChange, value, cssClass, ...rest }) => {
    
    const materialStyles = useStylesBase();

    return (
        <span className={cssClass ? cssClass : styles.container}>
            <MaterialCheckbox 
                classes={{root: materialStyles.root, checked: materialStyles.checked}}
                checked={checked}
                onChange={onChange}
                value={value}
                {...rest}
            />
            <div className={styles.content}>
                {children}
            </div>
        </span>
    );
};

Checkbox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    children: PropTypes.node,
    cssClass: PropTypes.string
};

Checkbox.defaultProps = {
    checked: false,
    onChange: () => console.log('Checkbox Changed'),
    value: 'foobar',
    children: 'Checkbox'
};

export default Checkbox;