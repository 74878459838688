// Core
import React from 'react';
import PropTypes from 'prop-types';

// Stylesheet
import styles from './index.module.scss';

const Question = ({ questionNumber, questionTotal, title, children, fullWidth }) => {
    return (
        <article className={`${styles.container} ${fullWidth ? styles.containerFullWidth : null}`}>
            <header className={`${styles.header}`}>
                <h4 className={`${styles.headerSubtitle} subtitle is-size-6`}>{`${questionNumber} z ${questionTotal}`}</h4>
                <h3 className={`${styles.headerTitle} title is-size-6`}>{title}</h3>
            </header>
            <main className={`${styles.content}`}>
                {children}
            </main>
        </article>
    );
};

Question.propTypes = {
    questionNumber: PropTypes.number,
    questionTotal: PropTypes.number,
    title: PropTypes.string,
    children: PropTypes.node
};

Question.defaultProps = {
    questionNumber: 1,
    questionTotal: 1,
    title: 'Question',
};

export default Question;