// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Custom Modules
import { textsStatic } from '../../texts';

// Custom Components
import Introduction from '../../components/primitive/Introduction';
import TextTextField from '../../components/primitive/TextInputs/TextField/TextTextField';
import Checkbox from '../../components/primitive/Checkbox';
import ButtonRowSingle from '../../components/primitive/ButtonRow/ButtonRowSingle';

// Stylesheet
import styles from './index.module.scss';

const IntroPage = ({ onClick, onChange, intro, details }) => {

    const [ consent, setConsent ] = useState(false);
    const [ error, setError ] = useState(null);

    const onClickInner = async () => {
        const areFieldsFilled = details.name && details.phone && details.email;
        if (!areFieldsFilled) {
            setError('By móc przejść do ankiety pola Imię, Telefon i Email nie mogą być puste');
        } else {
            onClick();
        }
    };

    const onChangeInner = key => e => {
        onChange(key, e.target.value);
    };

    return (
        <article className={`${styles.container}`}>
            <Introduction intro={intro}/>
            <main className={styles.inputs}>
                <TextTextField
                    value={details.name}
                    onChange={onChangeInner('name')}
                    label="Imię"
                    placeholder="Jan Kowalski"
                    forcedFormat
                >
                    Imię:
                </TextTextField>
                <TextTextField
                    value={details.phone}
                    onChange={onChangeInner('phone')}
                    label="Telefon"
                    placeholder="+48 123-456-789"
                    forcedFormat
                >
                    Telefon:
                </TextTextField>
                <TextTextField
                    value={details.email}
                    onChange={onChangeInner('email')}
                    label="Email"
                    placeholder="jankowalski@operator.com"
                    forcedFormat
                >
                    Email:
                </TextTextField>
            </main>
            <div className={styles.policy}>
                <Checkbox
                    checked={consent}
                    onChange={() => setConsent(!consent)}
                >
                    <p className={`${styles.policyText} is-size-6`}>
                        {textsStatic.summary.agreement.text}
                        <a rel="noopener noreferrer" target="_blank" href={textsStatic.summary.agreement.link.url}>{textsStatic.summary.agreement.link.text}</a>
                    </p>
                </Checkbox>
            </div>
            <div className={styles.error}>
                <p className={`is-size-7`}>{error}</p>
            </div>
            <div className={styles.buttons}>
                <ButtonRowSingle disabled={!consent} onClick={onClickInner} buttonText="Dalej"/>
            </div>
        </article>
    );
};

IntroPage.propTypes = {
    onClick: PropTypes.func,
    intro: PropTypes.shape({
        title: PropTypes.shape({
            bold: PropTypes.string,
            regular: PropTypes.string
        }),
        description: PropTypes.string
    })
};

export default IntroPage;