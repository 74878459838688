// Core
import React from 'react';
import PropTypes from 'prop-types';

// Third-Party Modules
import { TextField as MaterialTextField, MenuItem } from '@material-ui/core';

// Stylesheet
import styles from '../index.module.scss';
import { useStylesSelect } from '../index.material';

const Select = ({ label, value, optionList, onChange, children, ...rest }) => {

    const materialStyles = useStylesSelect();

    return (
        <div className={styles.containerSelect}>
            <div className={styles.contentSelect}>
                {children}
            </div>
            <MaterialTextField
                select
                className={materialStyles.root}
                label={label}
                value={value || ''}
                onChange={onChange}
                variant="outlined"
                {...rest}
            >
                {
                    optionList.map((option, i) => 
                        <MenuItem key={i} value={option.value}>
                            <p className={`${styles.text} is-size-6`}>{option.label}</p>
                        </MenuItem>     
                    )
                }
            </MaterialTextField>
        </div>
    );
};

Select.propTypes = {
    label: PropTypes.node,
    value: PropTypes.any,
    optionList: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string
        })
    ),
    onChange: PropTypes.func,
    children: PropTypes.node
};

Select.defaultProps = {
    label: 'label',
    onChange: () => console.log('TextField change'),
    children: 'Text Field',
    value: '',
    optionlist: [
        {
            value: 'foo',
            label: 'foo'
        },
        {
            value: 'bar',
            label: 'bar'
        }
    ]
};

export default Select;