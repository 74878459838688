// Core
import { connect } from 'react-redux';
// Actions
import { setSelectionByKey } from '../../../../ducks/noShop/Methods';

import Component from './Component';

const mapStateToProps = state => ({
    selection: state.noShop.q8.selection,
});

const mapDispatchToProps = {
    setSelectionByKey,
};

const Q8 = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Q8;